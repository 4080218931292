import React from "react";

export type ILogoLetterIconProps = React.SVGAttributes<SVGSVGElement>;

export const LogoLetterIcon = React.memo(function LockIcon(
  props: ILogoLetterIconProps
) {
  return (
    <svg viewBox="0 0 275 63.66501517760274">
      <defs id="SvgjsDefs4997">
        <linearGradient id="SvgjsLinearGradient5002">
          <stop id="SvgjsStop5003" stopColor="#6d7cff" offset="0"></stop>
          <stop id="SvgjsStop5004" stopColor="#ff51ff" offset="1"></stop>
        </linearGradient>
      </defs>
      <g
        id="SvgjsG4998"
        transform="matrix(1.5201768804886358,0,0,1.5201768804886358,-1.4593713130128836,-27.454393649762718)"
        fill="url(#SvgjsLinearGradient5002)"
      >
        <path d="M31.26 24.060000000000002 c-3.6 -3.78 -8.22 -6 -12.72 -6 l-17.58 0 l0 2.16 l17.58 0.06 c2.82 0 5.52 0.9 8.1 2.64 c5.04 3.54 8.4 9.9 8.4 16.08 c0 3.24 -0.72 6.24 -2.22 9.06 c-3 5.58 -9.06 9.66 -14.28 9.66 l-15.42 0 l0 -33.66 l14.94 0.06 c2.16 0 4.26 0.66 6.24 2.04 c4.02 2.7 6.66 7.86 6.66 12.84 c0 3.66 -1.56 7.44 -4.38 10.38 c-2.64 2.64 -5.82 4.32 -8.46 4.32 l-6.84 0 l0.06 -23.7 l-2.1 0 l-0.06 24.78 l0 1.08 l8.94 0 c3.3 0 7.14 -1.86 10.08 -4.98 c3.18 -3.42 4.92 -7.62 4.92 -11.88 c0 -4.32 -1.68 -8.64 -4.74 -12 c-3.3 -3.3 -6.9 -5.1 -10.32 -5.1 l-17.1 0 l0 38.04 l17.58 0 c4.32 0 9 -2.22 12.54 -6 c3.9 -4.02 6.06 -9.36 6.06 -14.94 s-2.04 -10.86 -5.88 -14.94 z M17.88 51.78 c1.68 0 3.42 -0.66 5.28 -1.92 c3.66 -2.58 6 -6.9 6 -10.92 c0 -2.16 -0.54 -4.26 -1.56 -6.3 c-2.1 -4.02 -5.88 -6.54 -9.72 -6.54 l-12.9 0.06 l0.06 29.82 l2.16 0 l0 -27.72 l10.68 -0.06 c1.38 0 2.82 0.54 4.32 1.56 c3.06 2.16 4.8 5.52 4.8 9.18 c0 2.46 -1.14 5.22 -3 7.32 s-4.14 3.36 -6.12 3.36 l-4.26 0 l0 2.16 l4.26 0 z"></path>
      </g>
    </svg>
  );
});
