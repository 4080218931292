import React from "react";
import { ExperienceIcon } from "../../icons/ExperienceIcon";
import { PrivacyIcon } from "../../icons/PrivacyIcon";
import { QuickIcon } from "../../icons/QuickIcon";

import css from "./why-us-block.module.scss";

export function WhyUsBlock() {
  return (
    <div className={css.whyUsBlock}>
      <div className={css.heading}>Why work with us</div>
      <div className={css.items}>
        <div className={css.item}>
          <div className={css.icon}>
            <QuickIcon />
          </div>
          <div className={css.title}>Easy and quick</div>
          <div className={css.description}>
            Our customers are making quick and easiest exchange operations from
            USDT or other crypto to Thai Baht.
          </div>
        </div>
        <div className={css.item}>
          <div className={css.icon}>
            <PrivacyIcon />
          </div>
          <div className={css.title}>Safety and privacy</div>
          <div className={css.description}>
            Since our launch we were following the highest standard for safety
            and privacy.
          </div>
        </div>
        <div className={css.item}>
          <div className={css.icon}>
            <ExperienceIcon />
          </div>
          <div className={css.title}>Experience</div>
          <div className={css.description}>
            Previous name of our Telegram service is Antrbit that was launched
            in 2018.
          </div>
        </div>
      </div>
    </div>
  );
}
