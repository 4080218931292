import React from "react";
import { LogoIcon } from "../../icons/LogoIcon";

import css from "./header.module.scss";

export function Header() {
  return (
    <header className={css.header}>
      <div className={css.logo}>
        <LogoIcon />
      </div>
    </header>
  );
}
