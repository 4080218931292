import { motion } from "framer-motion";
import React from "react";
import { TelegramIcon } from "../../icons/TelegramIcon";
import { WhatsappIcon } from "../../icons/WhatsappIcon";
import { redirectToTelegram, redirectToWhatsapp } from "../../util";

import css from "./socials.module.scss";

export function Socials() {
  return (
    <motion.div className={css.socials} layout>
      <div className={css.prompt}>Contact via: </div>
      <div className={css.list}>
        <button className={css.option} onClick={redirectToTelegram}>
          <TelegramIcon />
        </button>
        <button className={css.option} onClick={redirectToWhatsapp}>
          <WhatsappIcon />
        </button>
      </div>
    </motion.div>
  );
}
