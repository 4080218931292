import React from "react";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ProcessSteps } from "../ProcessSteps";
import { IStep } from "../ProcessSteps/ProcessSteps";

import css from "./how-it-works-block.module.scss";
import { BackIcon } from "../../icons/BackIcon";
import { Socials } from "../Socials";

const steps: IStep[] = [
  {
    label: "Exchange requested",
    content:
      "We agree on the exchange rate and amount, and the USDT network to use — TRC20 or ERC20.",
  },
  {
    label: "Transferring USDT",
    content:
      "Waiting for a USDT transfer to a specified wallet and checking for transaction confirmation.",
  },
  {
    label: "Withdraw from an ATM",
    content:
      "Funds are ready for withdrawal at the nearest ATM of the bank of your choice.",
  },
];

export function HowItWorksBlock() {
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [isSocialsVisible, setIsSocialsVisible] =
    React.useState<boolean>(false);

  const isLastStep = currentStep === steps.length;

  const prev = React.useCallback(() => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  }, [currentStep]);

  const next = React.useCallback(
    () => setCurrentStep(Math.min(currentStep + 1, steps.length)),
    [currentStep]
  );

  return (
    <div className={css.howItWorksBlock}>
      <div className={css.heading}>How does it work?</div>
      <ProcessSteps steps={steps} currentStep={currentStep} />
      <div className={css.actions}>
        <AnimatePresence>
          {currentStep > 0 && !isSocialsVisible && (
            <motion.button
              key="back"
              className={css.backButton}
              onClick={prev}
              layout
              initial={{ opacity: 0, y: 8 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 8 }}
            >
              <BackIcon />
            </motion.button>
          )}
          {currentStep <= steps.length && (
            <motion.button
              key="primary"
              className={cn(css.button, isSocialsVisible && css.social)}
              onClick={!isLastStep ? next : () => setIsSocialsVisible(true)}
              layout="preserve-aspect"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                backgroundColor: !isLastStep ? "#4ac365" : "#ffffff",
                color: !isLastStep ? "#ffffff" : "#4ac365",
              }}
              style={{ cursor: isSocialsVisible ? "auto" : "pointer" }}
              exit={{ opacity: 0 }}
            >
              <AnimatePresence>
                {!isSocialsVisible ? (
                  <motion.span
                    key="span"
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -40, opacity: 0 }}
                    layout="preserve-aspect"
                  >
                    {isLastStep ? "Exchange now!" : "Next step"}
                  </motion.span>
                ) : (
                  <motion.div
                    key="div"
                    initial={{ y: 40, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    layout="preserve-aspect"
                  >
                    <Socials />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
