import React from "react";

import css from "./calculator.module.scss";
import { Button } from "../Button";

enum ActiveType {
  usdt = "usdt",
  thb = "thb",
}

export interface ICalculatorProps {
  rate: number;
}

export function Calculator({ rate }: ICalculatorProps) {
  const [usdt, setUsdt] = React.useState<number>();
  const [thb, setThb] = React.useState<number>();

  const [active, setActive] = React.useState<ActiveType | null>(null);

  const [isSocialsVisible, setIsSocialsVisible] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (active === ActiveType.usdt) {
      const value = (usdt ?? 0) * rate;
      setThb(Number(value.toFixed(2)));
    }
  }, [active, rate, usdt]);

  React.useEffect(() => {
    if (active === ActiveType.thb) {
      const value = (thb ?? 0) / rate;
      setUsdt(Number(value.toFixed(2)));
    }
  }, [active, rate, thb]);

  const handleChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      dispatcher: React.Dispatch<React.SetStateAction<number | undefined>>
    ) => {
      const filteredValue = e.target.value.replace(/[^\d.]+/g, "");

      const value = Number(filteredValue);
      if (!Number.isNaN(value) && value > 0) {
        dispatcher(value);
      } else {
        dispatcher(0);
      }
    },
    []
  );

  return (
    <div className={css.calculator}>
      <div className={css.calculatorRow}>
        <label className={css.label}>
          <input
            type="text"
            className={css.calculatorInput}
            value={usdt}
            onFocus={() => setActive(ActiveType.usdt)}
            onBlur={() => setActive(null)}
            onChange={(e) => handleChange(e, setUsdt)}
          />
          <span className={css.inputLabel}>Pay</span>
        </label>
        <span className={css.calculatorCurrency}>USDT</span>
      </div>
      <div className={css.calculatorRow}>
        <label className={css.label}>
          <input
            type="text"
            className={css.calculatorInput}
            value={thb}
            onFocus={() => setActive(ActiveType.thb)}
            onBlur={() => setActive(null)}
            onChange={(e) => handleChange(e, setThb)}
          />
          <span className={css.inputLabel}>Get</span>
        </label>
        <span className={css.calculatorCurrency}>THB</span>
      </div>
      <div className={css.info}>
        Rate: {rate === 0 ? "loading..." : `~${rate}`}
      </div>
      <hr className={css.calculatorHr} />
      <Button
        label="Exchange"
        isSocialsVisible={isSocialsVisible}
        onClick={() => setIsSocialsVisible(true)}
      />
    </div>
  );
}
