import React from "react";

export type IPrivacyIconProps = React.SVGAttributes<SVGSVGElement>;

export const PrivacyIcon = React.memo(function LockIcon(
  props: IPrivacyIconProps
) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M11.5 12.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.05 17.5h4.9a2.5 2.5 0 0 0-4.9 0ZM8 18a3.5 3.5 0 1 1 7 0 .5.5 0 0 1-.5.5h-6A.5.5 0 0 1 8 18Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.5 10.5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2v-9Zm2-1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1h-10Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 6.5a4 4 0 0 1 4-4h1a4 4 0 0 1 4 4V9h-1V6.5a3 3 0 0 0-3-3h-1a3 3 0 0 0-3 3V9H7V6.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
