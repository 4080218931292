import { motion, useScroll, useTransform } from "framer-motion";
import React from "react";
import { useMediaQuery } from "usehooks-ts";
import { LogoLetterIcon } from "../../icons/LogoLetterIcon";
import { Calculator } from "../Calculator";

import css from "./exchanger-block.module.scss";

export interface IExchangeBlockProps {
  rate: number;
}

export function ExchangerBlock({ rate }: IExchangeBlockProps) {
  const scroll = useScroll();

  const isMobile = useMediaQuery("(max-width: 600px)");

  const transformY = useTransform(scroll.scrollYProgress, (v) =>
    Math.min(v * v * (isMobile ? 2500 : 500), 80)
  );

  return (
    <div className={css.exchangerBlock}>
      <motion.div className={css.logo} style={{ y: transformY }}>
        <LogoLetterIcon />
      </motion.div>
      <div className={css.blockSection}>
        <div className={css.offer}>
          <span className={css.offerHeading}>
            Your Crypto to Thai Cash just in 3 minutes
          </span>
          <span className={css.offerDescription}>
            Buy, sell USDT for Thai Baht in 3 minutes via cardless ATM
            withdrawal anywhere in Thailand.
          </span>
        </div>
      </div>
      <div className={css.blockSection}>
        <Calculator rate={rate} />
      </div>
    </div>
  );
}
