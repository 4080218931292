import React from "react";
import { Socials } from "../Socials";

import css from "./footer.module.scss";

export function Footer() {
  return (
    <footer className={css.footer}>
      <span>DecentraThai, 2022</span>
      <Socials />
      <div className={css.content}>
        Crypto exchange Bangkok, buy sell usdt Koh Samui, crypto Koh Pangan,
        thai baht for crypto Koh Tao, Phuket, Krabi, Hua Hin, Nakhon Si
        Thammarat, Chiang Mai, Pattaia, Suratthani, Satun, Songkla, Hat Yai,
        Amnat Charoen, Ang Thong, Bueng Kan, Buriram, Chachoengsao, Chai Nat,
        Chaiyaphum, Chanthaburi, Chiang Rai, Chonburi, Chumphon, Kalasin,
        Kamphaeng Phet, Kanchanaburi, Khon Kaen, Lampang, Lamphun, Loei,
        Lopburi, Mae Hong Son, Maha Sarakham, Mukdahan, Nakhon Nayok, Nakhon
        Pathom, Nakhon Phanom, Nakhon Ratchasima, Nakhon Sawan, Nan, Narathiwat,
        Nong Bua Lam Phu, Nong Khai, Nonthaburi, Pathum Thani, Pattani, Phang
        Nga, Phatthalung, Phayao, Phetchabun, Phetchaburi, Phichit, Phitsanulok,
        Phra Nakhon Si Ayutthaya, Phrae, Prachinburi, Prachuap Khiri Khan,
        Ranong, Ratchaburi, Rayong, Roi Et, Sa Kaeo, Sakon Nakhon, Samut Prakan,
        Samut Sakhon, Samut Songkhram, Saraburi, Sing Buri, Sisaket, Songkhla,
        Sukhothai (Sukhothai Thani), Suphan, Buri, Surin, Tak, Trang, Trat, Ubon
        Ratchathani, Udon Thani, Uthai Thani, Uttaradit, Yala, Yasothon
      </div>
    </footer>
  );
}
