import React from "react";

export type ICompletedIconProps = React.SVGAttributes<SVGSVGElement>;

export const CompletedIcon = React.memo(function LockIcon(
  props: ICompletedIconProps
) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M10.5 0C4.7103 0 0 4.7103 0 10.5C0 16.2897 4.7103 21 10.5 21C16.2897 21 21 16.2897 21 10.5C21 4.7103 16.2897 0 10.5 0ZM14.9945 8.69452L9.74452 13.9445C9.5907 14.0983 9.3891 14.175 9.1875 14.175C8.9859 14.175 8.7843 14.0983 8.63047 13.9445L6.00547 11.3195C5.69782 11.0119 5.69782 10.5136 6.00547 10.206C6.31312 9.89835 6.81135 9.89835 7.119 10.206L9.18697 12.274L13.8799 7.581C14.1876 7.27335 14.6858 7.27335 14.9935 7.581C15.3011 7.88865 15.3017 8.38688 14.9945 8.69452Z"
        fill="#4AC365"
      />
    </svg>
  );
});
