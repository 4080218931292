import React from "react";

export type IExperienceIconProps = React.SVGAttributes<SVGSVGElement>;

export const ExperienceIcon = React.memo(function LockIcon(
  props: IExperienceIconProps
) {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor">
      <path
        d="M421.9,178.6l-28.5-31.5l8.9-41.5c0.9-4-1.5-8-5.4-9.3l-40.4-13l-13-40.4c-1.3-3.9-5.3-6.2-9.3-5.4l-41.5,8.9l-31.5-28.5
			c-3-2.8-7.7-2.8-10.7,0l-31.5,28.5l-41.5-8.9c-4-0.9-8,1.5-9.3,5.4l-13,40.4l-40.4,13c-3.9,1.3-6.2,5.3-5.4,9.3l8.9,41.5
			l-28.5,31.5c-2.8,3-2.8,7.7,0,10.7l28.5,31.5l-8.9,41.5c-0.9,4,1.5,8,5.4,9.3l40.4,13l8.7,26.9L108.8,463c-1.2,3.4,0,7.2,2.9,9.3
			c3,2.1,7,1.9,9.7-0.5l38.6-32.6l8.5,49.8c0.6,3.6,3.6,6.3,7.2,6.6c0.2,0,0.5,0,0.7,0c3.3,0,6.4-2.1,7.5-5.3l55.1-151l11.6,10.5
			c1.5,1.4,3.4,2.1,5.4,2.1s3.8-0.7,5.4-2.1l11.6-10.5l55.1,151c1.2,3.2,4.2,5.3,7.5,5.3c0.2,0,0.5,0,0.7,0c3.6-0.3,6.6-3,7.2-6.6
			l8.5-49.8l38.6,32.6c2.8,2.3,6.8,2.5,9.7,0.5c3-2.1,4.2-5.9,2.9-9.3L348,311.5l8.7-26.9l40.4-13c3.9-1.3,6.2-5.3,5.4-9.3
			l-8.9-41.5l28.5-31.5C424.7,186.3,424.7,181.7,421.9,178.6z M179.2,456.6l-5.8-33.9c-0.5-2.8-2.4-5.2-5.1-6.2
			c-2.7-1-5.7-0.4-7.9,1.4l-26.2,22.2l40-109.8c1.1,0.3,2.3,0.3,3.5,0l41.5-8.9l7,6.4L179.2,456.6z M351.6,418
			c-2.2-1.9-5.2-2.4-7.9-1.4c-2.7,1-4.7,3.3-5.1,6.2l-5.8,33.9l-47-128.8l7-6.4l41.5,8.9c1.2,0.3,2.4,0.2,3.5,0l40,109.8L351.6,418z
			 M378.9,213.1c-1.7,1.9-2.4,4.5-1.9,7l8.3,38.4l-37.4,12.1c-2.4,0.8-4.4,2.7-5.2,5.2l-12.1,37.4l-38.4-8.3c-2.5-0.5-5.1,0.2-7,1.9
			L256,333.2l-29.1-26.4c-1.5-1.3-3.4-2.1-5.4-2.1c-0.6,0-1.1,0.1-1.7,0.2l-38.4,8.3l-12.1-37.4c-0.8-2.4-2.7-4.4-5.2-5.2
			l-37.4-12.1l8.3-38.4c0.5-2.5-0.2-5.1-1.9-7L106.8,184l26.4-29.1c1.7-1.9,2.4-4.5,1.9-7l-8.3-38.4l37.4-12.1
			c2.4-0.8,4.4-2.7,5.2-5.2l12.1-37.4l38.4,8.3c2.5,0.5,5.1-0.2,7-1.9L256,34.8l29.1,26.4c1.9,1.7,4.5,2.4,7,1.9l38.4-8.3l12.1,37.4
			c0.8,2.4,2.7,4.4,5.2,5.2l37.4,12.1l-8.3,38.4c-0.5,2.5,0.2,5.1,1.9,7l26.4,29.1L378.9,213.1z"
      />
      <path
        d="M256,72c-61.8,0-112,50.2-112,112s50.2,112,112,112s112-50.2,112-112S317.8,72,256,72z M256,280c-52.9,0-96-43.1-96-96
			s43.1-96,96-96s96,43.1,96,96S308.9,280,256,280z"
      />
      <path
        d="M263.6,100.9c-1.1-3.2-4.2-5.4-7.6-5.4s-6.4,2.2-7.6,5.4l-16,45.9l-48.6,1c-3.4,0.1-6.4,2.3-7.4,5.5
			c-1.1,3.2,0.1,6.8,2.8,8.8l38.8,29.4l-14.1,46.6c-1,3.3,0.2,6.8,3,8.8c2.8,2,6.5,2,9.3,0.1l39.9-27.8l39.9,27.8
			c1.4,1,3,1.4,4.6,1.4c1.7,0,3.3-0.5,4.7-1.5c2.8-2,3.9-5.5,3-8.8l-14.1-46.6l38.8-29.4c2.7-2.1,3.8-5.6,2.8-8.8
			c-1.1-3.2-4-5.5-7.4-5.5l-48.6-1L263.6,100.9z M280,182.3c-2.7,2-3.8,5.5-2.8,8.7l9,29.9L260.6,203c-1.4-1-3-1.4-4.6-1.4
			s-3.2,0.5-4.6,1.4l-25.6,17.8l9-29.9c1-3.2-0.2-6.7-2.8-8.7l-24.9-18.8l31.2-0.6c3.3-0.1,6.3-2.2,7.4-5.4l10.2-29.5l10.2,29.5
			c1.1,3.2,4,5.3,7.4,5.4l31.2,0.6L280,182.3z"
      />
    </svg>
  );
});
