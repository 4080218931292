import React from "react";

export type IWhatsappIconProps = React.SVGAttributes<SVGSVGElement>;

export const WhatsappIcon = React.memo(function LockIcon(
  props: IWhatsappIconProps
) {
  return (
    <svg
      enable-background="new 0 0 128 128"
      height="128px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 128 128"
      width="128px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64" cy="64" fill="#25D366" r="64" />
      <g>
        <path
          d="M92.346,35.49c-7.522-7.53-17.523-11.678-28.179-11.683c-21.954,0-39.826,17.868-39.833,39.831   c-0.004,7.022,1.831,13.875,5.316,19.913L24,104.193l21.115-5.538c5.819,3.171,12.369,4.844,19.036,4.847h0.017l0,0   c21.954,0,39.823-17.871,39.832-39.833C104.005,53.027,99.864,43.019,92.346,35.49 M64.168,96.774h-0.013   c-5.943-0.002-11.769-1.598-16.853-4.614l-1.209-0.718l-12.53,3.287l3.343-12.216l-0.787-1.256   c-3.315-5.27-5.066-11.361-5.062-17.619c0.006-18.253,14.859-33.104,33.121-33.104c8.844,0.002,17.155,3.451,23.407,9.71   c6.251,6.258,9.691,14.575,9.689,23.422C97.268,81.922,82.415,96.774,64.168,96.774 M82.328,71.979   c-0.996-0.499-5.889-2.904-6.802-3.239c-0.913-0.332-1.574-0.497-2.238,0.499s-2.571,3.239-3.153,3.903   c-0.58,0.664-1.16,0.748-2.156,0.249s-4.202-1.549-8.001-4.941c-2.96-2.637-4.958-5.899-5.538-6.895s-0.062-1.533,0.437-2.03   c0.448-0.446,0.996-1.162,1.493-1.744c0.497-0.582,0.663-0.997,0.995-1.66c0.332-0.664,0.167-1.245-0.083-1.743   c-0.25-0.499-2.24-5.398-3.068-7.391c-0.809-1.941-1.629-1.678-2.239-1.708c-0.582-0.028-1.245-0.036-1.908-0.036   c-0.663,0-1.742,0.249-2.654,1.246c-0.911,0.996-3.483,3.403-3.483,8.304c0,4.898,3.566,9.632,4.064,10.295   c0.498,0.663,7.018,10.718,17.002,15.029c2.374,1.024,4.229,1.637,5.674,2.097c2.384,0.759,4.554,0.65,6.27,0.394   c1.912-0.285,5.888-2.407,6.719-4.732c0.829-2.324,0.829-4.316,0.578-4.732C83.986,72.727,83.322,72.478,82.328,71.979"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
});
