import React from "react";
import { ExchangerBlock } from "../ExchangerBlock";
import { HowItWorksBlock } from "../HowItWorksBlock";
import { WhyUsBlock } from "../WhyUsBlock";

import css from "./content.module.scss";

export interface IContentProps {
  rate: number;
}

export function Content({ rate }: IContentProps) {
  return (
    <main className={css.content}>
      <ExchangerBlock rate={rate} />
      <WhyUsBlock />
      <HowItWorksBlock />
    </main>
  );
}
