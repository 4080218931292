import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useMediaQuery } from "usehooks-ts";
import { Indicator } from "./Indicator";

import css from "./process-steps.module.scss";

export type IStep = {
  label: string;
  content: string;
};

export type IProcessStepsProps = {
  steps: IStep[];
  currentStep: number;
};

export function ProcessSteps({ steps, currentStep }: IProcessStepsProps) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div className={css.processSteps}>
      <div className={css.line}>
        <motion.div
          className={css.progressLine}
          animate={{
            [isMobile ? "height" : "width"]:
              Math.min(
                Math.round((currentStep / (steps.length - 1)) * 100),
                100
              ) + "%",
            [isMobile ? "width" : "height"]: 5,
          }}
          transition={{ duration: 0.4, ease: "easeOut" }}
        />
        {steps.map((step, idx) => (
          <div key={idx} className={css.step}>
            <Indicator idx={idx} active={currentStep} total={steps.length} />
            <motion.div
              className={css.label}
              style={{
                x: isMobile
                  ? currentStep === idx
                    ? 28
                    : 14
                  : "calc(-50% + 11px)",
                y: isMobile ? 30 : 0,
              }}
              animate={{
                scale: currentStep === idx ? 1 : 0.8,
              }}
            >
              {step.label}
            </motion.div>
          </div>
        ))}
      </div>
      <div className={css.contentWrapper}>
        <AnimatePresence>
          <motion.div
            key={currentStep}
            className={css.content}
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {steps[currentStep]?.content ??
              "Cash recieved. Thank you for working with us 🤝"}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
