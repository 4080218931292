import React from "react";
import axios from "axios";
import "./App.css";
import { Header } from "./components/Header";
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

const DEFAULT_RATE = 32.4;

function App() {
  const [rate, setRate] = React.useState<number>(0);

  React.useEffect(() => {
    const job = async () => {
      const response = await axios.get(
        "https://raw.githubusercontent.com/decentrathai/usdt-thb.com/main/rate.json"
      );

      const rate = Number(response.data.rate);
      if (!rate || Number.isNaN(rate)) {
        setRate(DEFAULT_RATE);
      } else {
        setRate(rate);
      }
    };

    job();
  }, []);

  return (
    <div className="App">
      <Header />
      <Content rate={rate} />
      <Footer />
    </div>
  );
}

export default App;
