import React from "react";

export type IQuickIconProps = React.SVGAttributes<SVGSVGElement>;

export const QuickIcon = React.memo(function LockIcon(props: IQuickIconProps) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor">
      <path d="M14 4c-1.1895 0-2.3335.2539-3.4004.7549-.4995.2354-.7148.8301-.48 1.3301.2349.501.8306.7148 1.3306.4805C12.2485 6.1904 13.1064 6 14 6c3.3086 0 6 2.6914 6 6s-2.6914 6-6 6c-.7075 0-1.3994-.1211-2.0571-.3594-.5181-.1885-1.0928.0791-1.2813.5977-.1885.5195.0796 1.0928.5986 1.2813C12.1372 19.8389 13.0591 20 14 20c4.4111 0 8-3.5889 8-8S18.4111 4 14 4zM5 11c-.5522 0-1 .4473-1 1s.4478 1 1 1h4c.5522 0 1-.4473 1-1s-.4478-1-1-1H5z" />
      <path d="M11 8c0-.5527-.4478-1-1-1H7C6.4478 7 6 7.4473 6 8s.4478 1 1 1h3C10.5522 9 11 8.5527 11 8zM3 17h7c.5522 0 1-.4473 1-1s-.4478-1-1-1H3c-.5522 0-1 .4473-1 1S2.4478 17 3 17zM14 9c-.5522 0-1 .4473-1 1v2c0 .3789.2139.7246.5527.8945l2 1C15.6963 13.9658 15.8491 14 15.999 14c.3672 0 .7202-.2021.8955-.5527.2471-.4941.0469-1.0947-.4473-1.3418L15 11.3818V10C15 9.4473 14.5522 9 14 9z" />
    </svg>
  );
});
