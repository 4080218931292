import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Socials } from "../Socials";
import css from "./button.module.scss";

interface IButtonProps {
  label: string;
  isSocialsVisible: boolean;
  onClick: () => void;
}

export function Button(props: IButtonProps) {
  return (
    <button
      className={css.button}
      style={{ cursor: props.isSocialsVisible ? "auto" : "pointer" }}
      onClick={(e) => {
        if (props.isSocialsVisible) {
          e.preventDefault();
          e.stopPropagation();
        }
        props.onClick();
      }}
    >
      <AnimatePresence>
        {!props.isSocialsVisible ? (
          <motion.span
            key="span"
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -40, opacity: 0 }}
          >
            {props.label}
          </motion.span>
        ) : (
          <motion.div
            key="div"
            initial={{ y: 40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <Socials />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}
