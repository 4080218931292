import React from "react";
import cn from "classnames";
import { CompletedIcon } from "../../../icons/CompletedIcon";

import css from "./indicator.module.scss";
import { motion } from "framer-motion";

export type IIndicatorProps = {
  idx: number;
  active: number;
  total: number;
};

export function Indicator({ idx, active, total }: IIndicatorProps) {
  const isEmpty = React.useMemo(() => idx > active, [active, idx]);
  const isActive = React.useMemo(() => idx === active, [active, idx]);
  const isCompleted = React.useMemo(() => idx < active, [active, idx]);

  return (
    <div
      className={cn(
        css.indicator,
        isEmpty && css.empty,
        isActive && css.active,
        isCompleted && css.completed
      )}
    >
      {isActive && (
        <motion.div
          className={css.circle}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
        />
      )}
      {isCompleted && (
        <motion.div
          className={css.completedIcon}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
        >
          <CompletedIcon />
        </motion.div>
      )}
    </div>
  );
}
